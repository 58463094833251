import redminote13Celular from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-NOTE-13/Celular-XiaomiIceBlue.png';
import redminote13Celular2 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-NOTE-13/Celular-XiaomiBlack.png';
import redmi13Celular from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-13/Celular-Xiaomi-Redmi13.png';
import redmi13Celular1 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-13/Celular-Xiaomi-Redmi131.png';
import redmi13Celular2 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-13/Celular-Xiaomi-Redmi132.png';
import redmi13Celular3 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-13/Celular-Xiaomi-Redmi133.png';
import redminote13proCelularPurple from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-NOTE-13-PRO/Celular-Xiaomi-RedmiPurple.png';
import redminote13proCelularPurple2 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-NOTE-13-PRO/Celular-Xiaomi-RedmiPurple2.png';
import realmenotea3Celular from '../../../assets/imagenes/Store/Productos/CELULARES/REALME-NOTE-A3/Celular-Realme-Note-A3.png';
import realmenotea3Celular2 from '../../../assets/imagenes/Store/Productos/CELULARES/REALME-NOTE-A3/Celular-Realme-Note-A32.png';
import realmenotea3Celular3 from '../../../assets/imagenes/Store/Productos/CELULARES/REALME-NOTE-A3/Celular-Realme-Note-A33.png';
import redminote13gb6Celular from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-NOTE-13-6GB/Celular-Xiaomi-Redmi_Note13_6GB.png';
import redminote13gb6Celular2 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-NOTE-13-6GB/Celular-Xiaomi-Redmi_Note13_6GB2.png';
import redmi14cCelular from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-14C/Celular-Xiaomi-Redmi_14C.png';
import redmi14cCelular1 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-14C/Celular-Xiaomi-Redmi_14C1.png';
import redmi14cCelular2 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-14C/Celular-Xiaomi-Redmi_14C2.png';
import redmi14cCelular3 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-14C/Celular-Xiaomi-Redmi_14C3.png';

// Define la interfaz para cada producto
interface Product {
    name: string;
    category: string;
    image: string[]; // Array de strings para las rutas de las imágenes
    price: number;
    link: string;
    currentImageIndex: number;
    imageRotationInterval: number | null;
}

// Exporta el array de productos como constante
const celulares: Product[] = ([
    {
        name: 'Celular Xiaomi REDMI 14C 4GB + 256GB BLACK, STARRY BLUE, SAGE GREEN',
        category: 'Celulares',
        image: [redmi14cCelular, redmi14cCelular1, redmi14cCelular2, redmi14cCelular3],
        price: 479900,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Celular Xiaomi REDMI NOTE 13 6GB + 256GB MDN BLACK, ICE BLUE',
        category: 'Celulares',
        image: [redminote13gb6Celular, redminote13gb6Celular2],
        price: 567900,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Celular Xiaomi REDMI 13 8GB + 256GB SANDY GOLD, OCEAN BLUE, BLACK',
        category: 'Celulares',
        image: [redmi13Celular1, redmi13Celular, redmi13Celular2, redmi13Celular3],
        price: 633000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Celular REDMI NOTE A3 3GB + 64GB BLACK, FOREST GREEN, STAR BLUE)',
        category: 'Celulares',
        image: [realmenotea3Celular, realmenotea3Celular2, realmenotea3Celular3],
        price: 327400,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Celular Xiaomi REDMI NOTE 13 PRO PURPLE 8GB + 256GB',
        category: 'Celulares',
        image: [redminote13proCelularPurple, redminote13proCelularPurple2],
        price: 914900,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Celular Xiaomi REDMI NOTE 13 8GB + 256GB, Negro, Ice Blue',
        category: 'Celulares',
        image: [redminote13Celular, redminote13Celular2],
        price: 664900,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
]);

// Exporta el array para utilizarlo en otros archivos
export default celulares;
<!-- eslint-disable -->
<template>
    <div class="slide-contenedor">
        <!-- Itera sobre el array de slides con v-for y muestra solo el slide actual -->
        <div v-for="(slide, index) in slides" 
            :key="index" 
            :class="{ active: index === currentSlide }" 
            class="miSlider">
        
            <!-- Imagen -->
            <a v-if="slide.type === 'image'" :href="slide.link" target="_blank">
                <img :src="slide.src" alt="Slide Image" />
            </a>
            
            <!-- Video Local-->
            <div v-else-if="slide.type === 'video' && slide.src.endsWith('.mp4')" class="video-container" 
                @dblclick="handleVideoClick(slide.link)"
                @touchstart="handleVideoClick(slide.link)">                
                <video 
                    v-if="currentSlide === index"
                    autoplay
                    loop
                    controls
                >
                    <source :src="slide.src" type="video/mp4" />
                    Tu navegador no soporta videos.
                </video>    
            </div>

            <!-- Video de YouTube -->
            <div v-else-if="slide.type === 'video'" class="video-container">
                <iframe 
                    v-if="currentSlide === index"
                    :src="slide.src" 
                    frameborder="0" 
                    allow="autoplay; encrypted-media" 
                    allowfullscreen>
                </iframe>
                <button 
                    v-if="currentSlide === index"
                    @click="handleVideoClick(slide.link)" 
                    class="overlay-button">
                    !!! Ver Video Completo !!!
                </button>  
            </div> 
        </div>

        <!-- Navegación -->
        <div class="direcciones">
            <a href="#" class="atras" @click.prevent="changeSlide(-1)">&#10094;</a>
            <a href="#" class="adelante" @click.prevent="changeSlide(1)">&#10095;</a>
        </div>
        
        <!-- Indicadores -->
        <div class="barras">
            <span v-for="(slide, index) in slides" 
                :key="index" 
                class="barra" 
                :class="{ active: index === currentSlide }" 
                @click="setSlide(index)">
            </span>
        </div>
    </div>
</template>

<script lang="ts" src="./Slider.ts"></script>

<style scoped>

.slide-contenedor {
    position: relative; /* Posicionamiento relativo para los elementos internos */
    width: 100vw; /* Ocupa todo el ancho de la ventana */
    height: 100vh; /* Ocupa toda la altura de la ventana */
    overflow: hidden; /* Oculta contenido que desborda */
    margin: 0; /* Sin márgenes */
}

.miSlider {
    display: none; /* Oculta los slides no activos */
    transition: opacity 1s ease-in-out; /* Transición suave de opacidad */
    position: absolute; /* Posiciona cada slide de forma absoluta */
    top: 0; /* Alineación superior */
    left: 0; /* Alineación izquierda */
    width: 100%; /* Ocupa todo el ancho */
    height: 100%; /* Ocupa toda la altura */
}

.miSlider.active {
    display: block; /* Muestra el slide activo */
    opacity: 1; /* Totalmente visible */
}

.miSlider img,
.miSlider video,
.miSlider iframe {
    width: 100%; /* Ocupa todo el ancho */
    height: 100%; /* Ocupa toda la altura */
    object-fit: contain; /* Cambiado a 'contain' para que las imágenes no se corten */
    object-position: center; /* Centra la imagen en el contenedor */
    background-color: #9225c5; /* Añade un fondo negro para las áreas vacías, si es necesario */
}

.direcciones {
    position: absolute; /* Posicionamiento absoluto */
    top: 50%; /* Centro verticalmente */
    width: 100%; /* Ocupa todo el ancho */
    display: flex; /* Flexbox para alineación */
    justify-content: space-between; /* Espaciado entre elementos */
    align-items: center; /* Centro horizontalmente */
    font-size: 30px; /* Tamaño de fuente mayor */
    color: #fff; /* Color blanco para las flechas */
    z-index: 10; /* Asegura que se muestre encima */
    transform: translateY(-50%); /* Centra verticalmente */
}

.direcciones a {
    background: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    padding: 20px; /* Espaciado interno */
    border-radius: 5px; /* Bordes redondeados */
    transition: background 0.3s; /* Transición de fondo */
}

.atras {
    color: #fff;
}

.adelante {
    color: #fff;    
}

.direcciones a:hover {
    background: rgba(0, 0, 0, 0.7); /* Cambia el fondo al pasar el mouse */
}

.barras {
    position: absolute; /* Posicionamiento absoluto */
    bottom: 20px; /* Distancia desde la parte inferior */
    width: 100%; /* Ocupa todo el ancho */
    display: flex; /* Flexbox para alineación */
    justify-content: center; /* Centro horizontalmente */
}

.barra {
    cursor: pointer; /* Cambia el cursor al pasar el mouse */
    height: 5px; /* Altura de las barras */
    width: 25px; /* Ancho de las barras */
    margin: 0 5px; /* Espaciado entre barras */
    background: #dcdde1; /* Color de fondo por defecto */
    border-radius: 3px; /* Bordes redondeados */
    transition: background 0.3s; /* Transición de fondo */
}

.barra.active {
    background-color: #9225c5; /* Color activo de la barra */
}

.barra:hover {
    background-color: #9225c5; /* Color al pasar el mouse */
}

.video-container {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: contain;
    justify-content: center;
    align-items: center;
    position: relative; 
    overflow: hidden;
    background-color: #9225c5;
    cursor: pointer;
}

.play-icon {
    position: absolute; /* Posicionamiento absoluto */
    font-size: 70px; /* Tamaño del icono */
    color: white; /* Color del icono */
    z-index: 20; /* Asegúrate de que esté encima del video */
    transition: transform 0.2s; /* Añadir una transición para un efecto de hover */
}

.video-container:hover .play-icon {
    transform: scale(1.1); /* Aumenta el tamaño al pasar el mouse */
}

.video-iframe {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.overlay-button {
    display: inline-block;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 30px;
    background: linear-gradient(135deg, #9225c5, #f05a28);
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 20px;
    z-index: 50;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s, background 0.3s;
    cursor: pointer;
}

.overlay-button:hover {
    background: linear-gradient(135deg, #f05a28, #9225c5);
    transform: scale(1.05) rotate(2deg);
}

/* Ajustes Responsive */
@media (max-width: 1280px) and (max-height: 800px) {
    .slide-contenedor {
        height: 70vh; /* Para pantallas grandes, ocupa toda la altura */
    }
    .miSlider img,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 1024px) and (max-height: 1366px) {
    .slide-contenedor {
        height: 50vh; /* Para pantallas grandes, ocupa toda la altura */
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 1024px) and (max-height: 600px) {
    .slide-contenedor {
        height: 85vh; /* Para pantallas grandes, ocupa toda la altura */
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 912px) and (max-height: 1368px) {
    .slide-contenedor {
        height: 45vh; /* Para pantallas grandes, ocupa toda la altura */
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 640px) and (max-height: 360px) {
    .slide-contenedor {
        height: 100vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 540px) and (max-height: 720px) {
    .slide-contenedor {
        height: 50vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 430px) and (max-height: 932px) {
    .slide-contenedor {
        height: 32vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 375px) and (max-height: 667px) {
    .slide-contenedor {
        height: 45vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 360px) and (max-height: 740px) {
    .slide-contenedor {
        height: 38vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 1920px) and (max-height: 911px) {
    .slide-contenedor {
        height: 120vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 412px) and (max-height: 732px) {
    .slide-contenedor {
        height: 50vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 414px) and (max-height: 896px) {
    .slide-contenedor {
        height: 30vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

/* Regla específica para iPad Air */
@media (max-width: 820px) and (max-height: 1180px) and (-webkit-min-device-pixel-ratio: 2) 
and (device-width: 768px) and (orientation: portrait) {
    .slide-contenedor {
        height: 70vh; /* Ajuste especial para iPad Air */
    }
}

/* Regla específica para iPad Mini (Retina) */
@media (max-width: 768px) and (max-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) 
and (orientation: portrait) {
    .slide-contenedor {
        height: 55vh; /* Ajuste especial para iPad Mini */
    }
}

/* Regla específica para dispositivos con alta densidad de píxeles (iPhone 14 Pro Max) */
@media (max-width: 820px) and (max-height: 1180px) and (-webkit-min-device-pixel-ratio: 3) {
    .slide-contenedor {
        height: 40vh; /* Ajuste específico para iPhone 14 Pro Max */
    }
}


@media (max-width: 344px) and (max-height: 882px) {
    .slide-contenedor {
        height: 35vh; /* Para pantallas grandes, ocupa toda la altura */
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 412px) and (max-height: 914px) {
    .slide-contenedor {
        height: 55vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 320px) and (max-height: 480px) {
    .slide-contenedor {
        height: 60vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 360px) and (max-height: 640px) {
    .slide-contenedor {
        height: 50vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

@media (max-width: 600px) and (max-height: 960px) {
    .slide-contenedor {
        height: 50vh;
    }
    .miSlider img,
    .miSlider video,
    .miSlider iframe {
        object-fit: contain; 
        object-position: center;
    }
}

</style>

